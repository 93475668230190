import React from "react";
import { Card } from "@mui/material";
import linearGradient from "assets/theme/functions/linearGradient";
import colors from "assets/theme/base/colors";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function CampeonatoContainer({campeonato}) {

    const { info, gradients } = colors;
    const { cardContent } = gradients;

    return (
        <Card
            sx={{
                height: "250px",
                background: linearGradient(
                gradients.cardDark.main,
                gradients.cardDark.state,
                gradients.cardDark.deg
                ),
                /*background: `url("${campeonato?.league.logo}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top right',
                backgroundSize: 'auto 90px',
                backgroundPositionY: '10px'*/
                //background: `url("${jogo?.teams.home.logo}") repeat, url("${jogo?.teams.away.logo}")`,
                //backgroundRepeat: 'no-repeat, no-repeat',
                //backgroundPosition: 'top left, bottom right'
            }}>

            <VuiBox
            alignItems="center"
            sx={{display:"flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
            >
                <img src={campeonato?.league.logo}></img>
                <VuiBox>
                <VuiTypography variant="lg" color="white" mr="auto" fontWeight="bold">
                    {campeonato?.league?.name}
                </VuiTypography>
                </VuiBox>
                <VuiBox>
                <VuiTypography mr="auto">
                    {campeonato?.country?.name}
                </VuiTypography>
                </VuiBox>
            </VuiBox>
        </Card>
    )
}

export default CampeonatoContainer;
