import React from "react";
import { Card, Stack } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import VuiProgress from "components/VuiProgress";

function JogoContainer({jogo}) {
  const { info, gradients } = colors;
  const { cardContent } = gradients;

  return (
    
    <Card
      sx={{
        height: "100%",
        background: linearGradient(
          gradients.cardDark.main,
          gradients.cardDark.state,
          gradients.cardDark.deg
        ),
        background: `url("${jogo?.league.logo}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: 'auto 90px',
        backgroundPositionY: '10px'
        //background: `url("${jogo?.teams.home.logo}") repeat, url("${jogo?.teams.away.logo}")`,
        //backgroundRepeat: 'no-repeat, no-repeat',
        //backgroundPosition: 'top left, bottom right'
      }}
    >
      <VuiBox sx={{ width: "100%" }}>
        <VuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-beetween"
          sx={{ width: "100%" }}
          mb="40px"
        >
          <VuiTypography variant="lg" color="white" mr="auto" fontWeight="bold">
            {jogo?.league?.name}
          </VuiTypography>
        </VuiBox>
        <VuiBox
            sx={{ marginTop: "-10px", marginBottom: "20px" }}
          >
            {
              jogo?.fixture?.status?.elapsed ? jogo?.fixture?.status?.short == "FT" ?
                <>
                <VuiTypography color="text" variant="button" fontWeight="regular" mb="5px">
                  ENCERRADO
                </VuiTypography>
                </>

                :

                <>
                <VuiTypography color="text" variant="button" fontWeight="regular" mb="5px">
                  {jogo?.fixture.status.elapsed + "'"}
                </VuiTypography>
                <VuiProgress mar value={"" + (100 * jogo?.fixture.status.elapsed / 90)} variant="gradient" />
                </>

                :

                <>
                <VuiTypography color="text" variant="button" fontWeight="regular" mb="5px">
                  { new Date(jogo?.fixture.date).toLocaleDateString('pt-br') + ' - ' +   new Date(jogo?.fixture.date).toLocaleTimeString('pt-br')}
                </VuiTypography>
                </>
            }
            
        </VuiBox>
        <VuiBox
          display="flex"
          /*sx={({ breakpoints }) => ({
            [breakpoints.up("xs")]: {
              flexDirection: "column",
              gap: "16px",
              justifyContent: "center",
              alignItems: "center",
            },
            [breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            },
          })}*/
        >
          <Stack
            direction="column"
            spacing="20px"
            minWidth="100%"
            maxWidth="100%"
            sx={{
              mr: "auto",
            }}
          >
            <VuiBox
              display="flex"
              width="100%"
              p="20px 22px"
              sx={{background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                justifyContent: "space-between"}}
              /*
              sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                justifyContent: "space-between",
                [breakpoints.up("xl")]: {
                  maxWidth: "100% !important",
                },
                [breakpoints.up("xxl")]: {
                  minWidth: "180px",
                  maxWidth: "100% !important",
                },
              })}*/
            >
              <div style={{display: 'flex'}}>
                <img width={'30px'} src={jogo?.teams.home.logo} style={{marginRight: '15px'}}></img>
                <VuiTypography color="white" variant="lg" fontWeight="bold">
                  {jogo?.teams.home.name}
                </VuiTypography>
              </div>
              <VuiBox color="white" sx={{ display: jogo?.fixture?.status?.elapsed ? "block" : "none" }}> { jogo?.goals?.home > 0 ? jogo?.goals?.home : 0 } </VuiBox>
            </VuiBox>
            <VuiBox
              display="flex"
              width="100%"
              p="20px 22px"
              sx={{background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                justifyContent: "space-between"}}
              /*sx={({ breakpoints }) => ({
                background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                borderRadius: "20px",
                justifyContent: "space-between",
                [breakpoints.up("xl")]: {
                  maxWidth: "100% !important",
                },
                [breakpoints.up("xxl")]: {
                  minWidth: "180px",
                  maxWidth: "100% !important",
                },
              })}*/
            >
              <div style={{display: 'flex'}}>
                <img width={'30px'} src={jogo?.teams.away.logo} style={{marginRight: '15px'}}></img>
                <VuiTypography color="white" variant="lg" fontWeight="bold">
                  {jogo?.teams.away.name}
                </VuiTypography>
              </div>
              <VuiBox color="white" sx={{ display: jogo?.fixture?.status?.elapsed ? "block" : "none" }}> { jogo?.goals?.away > 0 ? jogo?.goals?.away : 0 } </VuiBox>
            </VuiBox>
          </Stack>
        </VuiBox>
      </VuiBox>
    </Card>
  );
}

export default JogoContainer;
