import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Card, LinearProgress, Stack } from "@mui/material";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

// Vision UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import linearGradient from "assets/theme/functions/linearGradient";

// Vision UI Dashboard React base styles
import typography from "../../assets/theme/base/typography";
import colors from "../../assets/theme/base/colors";

// React icons
import { IoIosRocket } from "react-icons/io";
import { IoGlobe } from "react-icons/io5";
import { IoBuild } from "react-icons/io5";
import { IoWallet } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";


import JogoContainer from "./components/JogoContainer";
import Divider from "components/Divider";
import { LST_STATUS_JOGOS_AOVIVO } from "providers/jogo-status-provider";
import { LST_STATUS_JOGOS_ENCERRADOS } from "providers/jogo-status-provider";

const STR_JOGOS_AO_VIVO = "JOGOS AO VIVO"
const STR_PROXIMOS_JOGOS = "PRÓXIMOS JOGOS"
const STR_JOGOS_ENCERRADOS = "JOGOS ENCERRADOS"

function Inicio() {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const [ jogosAoVivo, setJogosAoVivo ] = useState([]);
  const [ jogosEncerrados, setJogosEncerrados ] = useState([]);
  const [ jogosProximos, setJogosProximos ] = useState([]);

  useEffect(() => {
    buscaJogos();
    const id = setInterval(buscaJogos, 60000);
    return () => clearInterval(id);
 }, []);

  const buscaJogos = async () => {
    const response  = await fetch("http://nodered.thiagosol.com/live-score-app/fixtures/today");
    const data = await response.json();
    const jogosAoVivo = data.filter(d => LST_STATUS_JOGOS_AOVIVO.some(s => s == d.fixture.status.short))
    const jogosEncerrados = data.filter(d => LST_STATUS_JOGOS_ENCERRADOS.some(s => s == d.fixture.status.short))
    const jogosProximos = data.filter(d => !LST_STATUS_JOGOS_AOVIVO.concat(LST_STATUS_JOGOS_ENCERRADOS).some(s => s == d.fixture.status.short))
    setJogosAoVivo(jogosAoVivo);
    setJogosEncerrados(jogosEncerrados);
    setJogosProximos(jogosProximos);
  }

  return (
    <DashboardLayout>
      { 
        <DashboardNavbar /> 
      }
      <VuiBox py={3}>
        <VuiBox mb={3} sx={{ display: jogosAoVivo && jogosAoVivo.length > 0 ? "block" : "none", marginTop: '15px' }}>
          <VuiTypography variant="lg" mr="auto" fontWeight="bold">
            { STR_JOGOS_AO_VIVO }
          </VuiTypography>
          <Grid container spacing="18px">
            {
              jogosAoVivo.map(jogo =>
                <Grid key={jogo._id} item  xs={12} md={6} xl={4}>
                  <JogoContainer jogo={jogo} />
                </Grid>
              )
            }
          </Grid>
        </VuiBox>
        <VuiBox mb={3} sx={{ display: jogosProximos && jogosProximos.length > 0 ? "block" : "none", marginTop: '15px' }}>
          <VuiTypography variant="lg" mr="auto" fontWeight="bold">
            { STR_PROXIMOS_JOGOS }
          </VuiTypography>
          <Grid container spacing="18px">
            {
              jogosProximos.map(jogo =>
                <Grid key={jogo._id} item  xs={12} md={6} xl={4}>
                  <JogoContainer jogo={jogo} />
                </Grid>
              )
            }
          </Grid>
        </VuiBox>
        <VuiBox mb={3} sx={{ display: jogosEncerrados && jogosEncerrados.length > 0 ? "block" : "none", marginTop: '15px' }}>
          <VuiTypography variant="lg" mr="auto" fontWeight="bold">
            { STR_JOGOS_ENCERRADOS }
          </VuiTypography>
          <Grid container spacing="18px">
            {
              jogosEncerrados.map(jogo =>
                <Grid key={jogo._id} item  xs={12} md={6} xl={4}>
                  <JogoContainer jogo={jogo} />
                </Grid>
              )
            }
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Inicio;
