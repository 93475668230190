import React from "react";
import { Hr } from "./style";

function Divider({style = null}) {

  return (
    <Hr style={style} />
  );
}

export default Divider;
