import DashboardNavbar from "components/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import CampeonatoContainer from "../card";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { jogosData } from "./data/jogosData";
import Table from "components/Table";
import VuiTypography from "components/VuiTypography";

function DetalhesJogosCampeonato() {
    const [ jogos, setJogos ] = useState([]);
    const [ columns, setColumns ] = useState([]);
    const [ rows, setrRows ] = useState([]);
    const [ campeonato, setCampeonato ] = useState();

    const { id } = useParams();

    useEffect(() => {
        buscaDetalhesJogosCampeonatos();
        buscaCampeonatos();
    }, []);

   const buscaDetalhesJogosCampeonatos = async () => {
    const response  = await fetch(`http://nodered.thiagosol.com/live-score-app/leagues/fixtures?league=${id}`);
    const data = await response.json();
    setJogos(data);
    const { columns, rows } = jogosData(data);
    setColumns(columns)
    setrRows(rows)
  }

  const buscaCampeonatos = async () => {
    const response  = await fetch("http://nodered.thiagosol.com/live-score-app/leagues");
    const data = await response.json();
    setCampeonato(data.filter(c => c._id == id)[0]);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox display='flex'>
        <img height={'40px'} src={campeonato?.country.flag}></img>
        <VuiTypography color="white" variant="h3" fontWeight="bold" mb="18px" sx={{marginLeft: '10px'}}>
          {campeonato?.country.name + " - " + campeonato?.league.name}
        </VuiTypography>
      </VuiBox>
      <VuiBox mb={3} sx={{ marginTop: '15px' }}>
          { /*<VuiTypography variant="lg" mr="auto" fontWeight="bold">
            { STR_JOGOS_AO_VIVO }
          </VuiTypography> */
            }
        <VuiBox
          sx={{
            "& th": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </VuiBox>
      </VuiBox>

    </DashboardLayout>
  )

}

export default DetalhesJogosCampeonato;
