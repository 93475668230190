import DashboardNavbar from "components/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import CampeonatoContainer from "./card";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

function Campeonatos() {
    const [ campeonatos, setCampeonatos ] = useState([]);
  
    useEffect(() => {
        buscaCampeonatos();
    }, []);

   const buscaCampeonatos = async () => {
    const response  = await fetch("http://nodered.thiagosol.com/live-score-app/leagues");
    const data = await response.json();
    setCampeonatos(data);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox mb={3} sx={{ marginTop: '15px' }}>
          { /*<VuiTypography variant="lg" mr="auto" fontWeight="bold">
            { STR_JOGOS_AO_VIVO }
          </VuiTypography> */
            }
            <Grid container spacing="18px">
              {
                campeonatos.map(campeonato =>
                  <Grid key={campeonato._id} item  xs={12} md={4} xl={3}>
                    <Link to={`/jogos-campeonato/${campeonato._id}`}>
                    <CampeonatoContainer campeonato={campeonato} />
                    </Link>
                  </Grid>
                )
              }
            </Grid>
        </VuiBox>

    </DashboardLayout>
  )

}

export default Campeonatos;
