import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiAvatar from "components/VuiAvatar";
import VuiBadge from "components/VuiBadge";

// Images
import { findAllByDisplayValue } from "@testing-library/react";
import { getJogoStatus } from "providers/jogo-status-provider";

function Texto( { txt } ) {
  
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox display="flex" flexDirection="column">
        <VuiTypography variant="button" color="white" fontWeight="medium">
          {txt}
        </VuiTypography>
      </VuiBox>
    </VuiBox>
  );
}

function Escudo( { logo, alt } ) {
  const espacamento = "5px";
  return (
    <VuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <VuiBox mr={2}>
        <VuiAvatar src={logo} alt={alt} size="sm" />
      </VuiBox>
    </VuiBox>
  );
}

function Function({ job, org }) {
  return (
    <VuiBox display="flex" flexDirection="column">
      <VuiTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </VuiTypography>
      <VuiTypography variant="caption" color="text">
        {org}
      </VuiTypography>
    </VuiBox>
  );
}

export const jogosData = (jogos) => {

  const rows = jogos.map(jogo => {
    let jogoStatus = getJogoStatus(jogo);
    return {
      data: <Texto txt={ new Date(jogo?.fixture.date).toLocaleDateString('pt-br') + ' - ' +   new Date(jogo?.fixture.date).toLocaleTimeString('pt-br').substring(0,5) } />,
      jogo: 
        <VuiBox display="flex">
          <Texto txt={jogo.teams.home.name} />
          <Escudo logo={jogo.teams.home.logo} alt={jogo.teams.home.name} />
        </VuiBox>,
      golsM: <Texto txt={jogo.goals.home} />,
      x: <Texto txt={"x"} />,
      golsV: <Texto txt={jogo.goals.away} />,
      visitante:
        <VuiBox display="flex">
          <Escudo logo={jogo.teams.away.logo} alt={jogo.teams.away.name} />
          <Texto txt={jogo.teams.away.name} />
        </VuiBox>,
      status:
        <VuiBadge
          variant="standard"
          badgeContent={jogoStatus.name.toUpperCase()}
          size="xs"
          container
          sx={({ palette: { white, success, primary, secondary }, borders: { borderRadius, borderWidth } }) => ({
            background: jogoStatus.name.toUpperCase() == "AO VIVO" ? success.main : jogoStatus.name.toUpperCase() == "ENCERRADO" ? secondary.main : primary.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
    }
  })

  console.log(rows)

  return {

  columns: [
    { name: "data", align: "left", isNameVisible: true },
    { name: "jogo", align: "right", isNameVisible: false },
    { name: "golsM", align: "center", isNameVisible: false },
    { name: "x", align: "center", isNameVisible: false },
    { name: "golsV", align: "center", isNameVisible: false },
    { name: "visitante", align: "left", isNameVisible: false },
    { name: "status", align: "center", isNameVisible: false },
    /*{ name: "function", align: "left" },
    { name: "status", align: "center" },
    { name: "employed", align: "center" },
    { name: "action", align: "center" },*/
  ],

  rows: rows /* [
    {
      jogo: <Jogo team={team} goal={goal} />,
      function: <Function job="Manager" org="Organization" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Online"
          color="success"
          size="xs"
          container
          sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          23/04/18
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
    {
      author: <Author image={avatar2} name="Alexa Liras" email="alexa@simmmple.com" />,
      function: <Function job="Programator" org="Developer" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Offline"
          size="xs"
          container
          sx={({ palette: { white }, borders: { borderRadius, borderWidth } }) => ({
            background: "unset",
            border: `${borderWidth[1]} solid ${white.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          11/01/19
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
    {
      author: <Author image={avatar3} name="Laurent Michael" email="laurent@simmmple.com" />,
      function: <Function job="Executive" org="Projects" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Online"
          color="success"
          size="xs"
          container
          sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          19/09/17
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
    {
      author: <Author image={avatar1} name="Freduardo Hill" email="freduardo@simmmple.com" />,
      function: <Function job="Programator" org="Developer" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Online"
          color="success"
          size="xs"
          container
          sx={({ palette: { white, success }, borders: { borderRadius, borderWidth } }) => ({
            background: success.main,
            border: `${borderWidth[1]} solid ${success.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          24/12/08
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
    {
      author: <Author image={avatar5} name="Daniel Thomas" email="daniel@simmmple.com" />,
      function: <Function job="Manager" org="Executive" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Offline"
          size="xs"
          container
          sx={({ palette: { white }, borders: { borderRadius, borderWidth } }) => ({
            background: "unset",
            border: `${borderWidth[1]} solid ${white.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          04/10/21
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
    {
      author: <Author image={avatar6} name="Mark Wilson" email="mark@simmmple.com" />,
      function: <Function job="Programtor" org="Developer" />,
      status: (
        <VuiBadge
          variant="standard"
          badgeContent="Offline"
          size="xs"
          container
          sx={({ palette: { white }, borders: { borderRadius, borderWidth } }) => ({
            background: "unset",
            border: `${borderWidth[1]} solid ${white.main}`,
            borderRadius: borderRadius.md,
            color: white.main,
          })}
        />
      ),
      employed: (
        <VuiTypography variant="caption" color="white" fontWeight="medium">
          14/09/20
        </VuiTypography>
      ),
      action: (
        <VuiTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          Edit
        </VuiTypography>
      ),
    },
  ],*/
}};
